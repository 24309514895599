:root {
  --ash-gray: #A4B5B3;
  --timberwolf: #DBD3C9;
  --meyer-lemon: #FAE681;
  --orange:  #FFA101;
  --sky-blue:  #B3DEE5;
  --navy-blue: #31525B;
}

#root{
  background-color: var(--timberwolf);
  min-height: 90vh;
}

.App {
  text-align: center;
  background-color: var(--timberwolf);
  min-height: 90vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--timberwolf);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--navy-blue);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jumbotron-header{
  background-color: var(--navy-blue);
  color:  var(--timberwolf);
  filter:  drop-shadow(8px 8px 10px #292b2c);
}


.nav-tabs{
  margin: auto;
  width: 90%;
  overflow-x: auto;
  overflow-y: hidden;
  display: -webkit-box;
  display: -moz-box;
}

.teal-btn{
  color: var(--timberwolf);
  background-color: var(--navy-blue);
  border-radius: 6px 6px 0px 0px;
  box-shadow: 15px 8px 15px rgba(0, 0, 0, 0.2);
}

.teal-btn:hover,.green-btn:hover, .teal-btn:active, .green-btn:active{
  color: var(--timberwolf);
}

.btn-active{
  color: var(--navy-blue);
  background-color: var(--ash-gray);
}

.green-btn{
  color: var(--ash-gray);
  background-color: var(--navy-blue);
  border-radius: 6px 6px 0px 0px;
  box-shadow: 15px 8px 15px rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width:  768px){
  .tech-icon{
    width: 25% !important;
  }
}

.icon-row{
  display: flex;
  margin: auto;
  width: 75%;
}

.icon-col {
  flex: 25%;
  padding: 5px;
}

.tech-icon{
  width: 50%;
}

.teal-row{
  color: var(--navy-blue);
  background-color: var(--ash-gray);
  margin: auto;
  border-radius: 0px 6px 6px 6px;
  filter:  drop-shadow(8px 8px 10px #292b2c);
  width: 90%;
}

.green-row{
  color: var(--ash-gray);
  background-color: var(--navy-blue);
  margin: auto;
  border-radius: 6px 6px 6px 6px;
  filter:  drop-shadow(8px 8px 10px #292b2c);
  width: 90%;
}

@media only screen and (min-width:  768px){
  .left-header{
    width: 25%;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .right-body{
    padding-top: 48px;
    padding-bottom: 48px;
    padding-right: 48px;
    width: 75%;
    text-align: left !important;
  }
}
.left-header{

  border-radius: 0px 0px 0px 6px;
}

.right-body{
  margin: auto;
  text-align: center;
  border-radius: 0px 6px 6px 0px;
}

.footer {
    background-color: #292b2c;
}

.mt-auto {
    margin-top: auto !important;
}

.py-3 {
    padding-bottom: 1rem! important;
    padding-top: 1rem !important;
}